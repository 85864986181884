import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 0,
  "title": "Vue.js",
  "subtitle": "Accept Danish MitID, Swedish BankID, Norwegian BankID and more eID logins in your Vue.js application with @criipto/auth-js."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide shows how to use Criipto Verify in a Vue.js application.`}</p>
    <p>{`Read `}<a parentName="p" {...{
        "href": "https://github.com/criipto/criipto-auth.js"
      }}>{`the full `}<inlineCode parentName="a">{`@criipto/auth-js`}</inlineCode>{` documentation`}</a>{` or download a `}<a parentName="p" {...{
        "href": "https://github.com/criipto/samples/tree/master/Verify/vue-3"
      }}>{`sample Vue 3 application from GitHub`}</a>{`.`}</p>
    <h2>{`Register Your Application in Criipto Verify`}</h2>
    <p>{`After you signed up for Criipto Verify, you must `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com/applications/add"
      }}>{`register an application`}</a>{` before you can try logging in with any eID.`}</p>
    <p>{`Once your application is registered, the dashboard will contain the necessary details to configure your application to communicate with Criipto Verify.
You will need the following information from the General tab of your application settings:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Client ID`}</em>{` to identify your application to Criipto Verify.`}</li>
      <li parentName="ul"><em parentName="li">{`Domain`}</em>{` on which you will be communicating with Criipto Verify.`}</li>
    </ul>
    <h3>{`Register callback URLs`}</h3>
    <p>{`Before you can start sending authentication requests to Criipto Verify, you need to register the URLs on which you want to receive the returned `}<em parentName="p">{`JSON Web Token`}</em>{`, JWT.`}</p>
    <p>{`The Callback URL of your application is the URL where Criipto Verify will redirect to after the user has authenticated in order for the OpenID Connect middleware to complete the authentication process.`}</p>
    <p>{`You will need to add this URL to the list of allowed URLs for your application.`}</p>
    <p>{`If you are running your application locally, your callback URL might look like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`http://localhost:5173
`}</code></pre>
    <p>{`When you deploy your application to a different URL, you will also need to ensure you add that URL to the Callback URLs field in your application settings.`}</p>
    <h2>{`Install SDK`}</h2>
    <p>{`Using `}<a parentName="p" {...{
        "href": "https://npmjs.org/"
      }}>{`npm`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install --save @criipto/auth-js
`}</code></pre>
    <h2>{`Initialize SDK with your application details`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// App.vue

import CriiptoAuth from '@criipto/auth-js';

var criiptoAuth = new CriiptoAuth({
  domain: '{{YOUR_CRIIPTO_DOMAIN}}',
  clientID: '{{YOUR_CLIENT_ID}}',
  store: sessionStorage,
});
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`CriiptoAuth`}</inlineCode>{` constructor takes an object with the following parameters:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`domain (required, string)`}</inlineCode>{`: The domain your Criipto Application belongs to, such as `}<inlineCode parentName="li">{`example.criipto.id`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`clientID (required, string)`}</inlineCode>{`: The Client ID for your Criipto Application.`}</li>
    </ul>
    <p>{`Use your application's `}<a parentName="p" {...{
        "href": "#register-your-application-in-criipto-verify"
      }}>{`domain and client ID`}</a>{` as the values for `}<inlineCode parentName="p">{`domain`}</inlineCode>{` and `}<inlineCode parentName="p">{`clientID`}</inlineCode>{` respectively.`}</p>
    <p>{`Additional authorization parameters can be passed in the `}<inlineCode parentName="p">{`CriiptoAuth`}</inlineCode>{` constructor as well. For all available options, see `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro#authorize-request-parameters"
      }}>{`the full list of supported parameters`}</a>{`.`}</p>
    <h2>{`Authorization methods`}</h2>
    <p>{`You can use the following methods to authorize the user:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#authorization-with-popup"
        }}><inlineCode parentName="a">{`criiptoAuth.popup.authorize`}</inlineCode></a>{` - Opens a popup window to authenticate the user.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#authorization-with-redirect"
        }}><inlineCode parentName="a">{`criiptoAuth.redirect.authorize`}</inlineCode></a>{` - Redirects the user to the Criipto Verify login page.`}</li>
    </ul>
    <h3>{`Authorization with popup`}</h3>
    <p>{`Will open a popup window to authenticate the user.`}</p>
    <h4>{`authorize`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// App.vue
// Using the reactive method and Composition API of Vue.js 3

<script setup lang="ts">
import CriiptoAuth from '@criipto/auth-js';
import { reactive } from 'vue';

...
const state = reactive({
  claims: {},
});

const loginWithEid = () => {
   criiptoAuth.popup.authorize({
    width: 300,
    height: 400,
    redirectUri: 'http://localhost:5173',
    acrValues: 'urn:grn:authn:dk:mitid:substantial',
   })
   .then((result) => {
    console.log(result, result.id_token, result.claims);
    state.claims = result.claims;
};
...
</script>

<template>
  ...
    <button @click="loginWithEid">Login with eID</button>
    <ul>
      <li v-for="(value, key) in state.claims" :key="key">
        {{ key }}: {{ value }}
      </li>
    </ul>
  ...
</template>
`}</code></pre>
    <h3>{`Authorization with redirect`}</h3>
    <p>{`Will redirect the user to the Criipto Verify login page.`}</p>
    <h4>{`authorize`}</h4>
    <p>{`Redirects the users browser tab to the authorization url. After authorization, the user will be redirected back to the provided `}<inlineCode parentName="p">{`redirectUri`}</inlineCode>{`.
The `}<inlineCode parentName="p">{`redirectUri`}</inlineCode>{` should be `}<a parentName="p" {...{
        "href": "#register-callback-URLs"
      }}>{`whitelisted in the "Callback URLs" section`}</a>{` in your Criipto application settings.`}</p>
    <h4>{`match`}</h4>
    <p>{`Will check for the `}<inlineCode parentName="p">{`code`}</inlineCode>{` or `}<inlineCode parentName="p">{`id_token`}</inlineCode>{` parameter in the URL query parameters or hash of the current `}<inlineCode parentName="p">{`window.location`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul">{`If the `}<inlineCode parentName="li">{`id_token`}</inlineCode>{` parameter is present, it will return the result.`}</li>
      <li parentName="ul">{`If the `}<inlineCode parentName="li">{`code`}</inlineCode>{` parameter is present, it will perform a PKCE token exchange and return the result. For more information, see `}<a parentName="li" {...{
          "href": "/verify/getting-started/oidc-intro/#exchange-the-code-for-a-token"
        }}>{`this section`}</a>{`.`}</li>
    </ul>
    <p>{`As soon as the user has been redirected back to your application after authentication, you can call the `}<inlineCode parentName="p">{`match`}</inlineCode>{` method to get the `}<inlineCode parentName="p">{`id_token`}</inlineCode>{` or `}<inlineCode parentName="p">{`code`}</inlineCode>{` parameter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Login.vue

<script setup lang="ts">
import criiptoAuth from '../criipto-auth';
...
const loginWithEid = () => {
  criiptoAuth.redirect
    .authorize({
      redirectUri: 'http://localhost:5173/profile',
      prompt: 'login',
    })
    .then((result) => {
      console.log('result', result);
    })
    .catch((error) => {
      console.log(error);
    });
};
...
</script>

<template>
    ...
    <button @click="loginWithEid">Login with eID</button>
    ...
</template>

// Profile.vue
<script setup lang="ts">
import { reactive, onMounted } from 'vue';
import criiptoAuth from '../criipto-auth';

const state = reactive({
  userName: 'user',
});

function matchCallback() {
  criiptoAuth.redirect
    .match()
    .then((result) => {
      if (result && result.claims) {
        state.userName = result.claims.name;
        console.log('result', result);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

onMounted(() => {
  matchCallback();
});
</script>

<template>
  ...
    <h1>Hello, {{state.name}}</h1>
  ...
</template>
`}</code></pre>
    <p><inlineCode parentName="p">{`prompt: 'login'`}</inlineCode>{` will force the user to re-authenticate even if they have already logged in. See `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro#authorize-request-parameters"
      }}>{`the list of supported parameters`}</a>{` for more information.`}</p>
    <h2>{`Logging user out`}</h2>
    <p>{`If you have SSO enabled for your domain and you are not using `}<inlineCode parentName="p">{`prompt='login'`}</inlineCode>{` you can use `}<inlineCode parentName="p">{`logout()`}</inlineCode>{` to clear the users SSO session.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`criiptoAuth.logout({
  redirectUri: 'http://localhost:5173',
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      